// https://ads.tiktok.com/help/article?aid=10028
// Deduplication: https://ads.tiktok.com/marketing_api/docs?id=1723170195197953
// TODO check all events and add more if there are any

/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load TikTok Ads event listeners
 * */

// Pixel load event listener
jQuery(document).on("pmwLoadPixels", () => {

	if (wpmDataLayer?.pixels?.tiktok?.pixel_id && !wpmDataLayer?.pixels?.tiktok?.loaded) {
		if (wpm.consent.canPixelBeFired("marketing", "TikTok")) wpm.loadTikTokPixel()
	}
})

function handleTikTokEvent(eventName, event, payload) {
  try {
    if (!wpmDataLayer?.pixels?.tiktok?.loaded) return;

    ttq.track(
      payload.tiktok.event,
      payload.tiktok.properties,
      {event_id: payload.tiktok.event_id},
    );

    pmw.console.log(`TikTok Pixel: ${payload.tiktok.event} event sent`, payload.tiktok);

  } catch (e) {
    console.error(e);
  }
}

const tikTokEvents = [
  "wpmClientSideAddToCart",
  "wpmClientSideBeginCheckout",
  "wpmClientSideViewItem",
  "wpmClientSideAddToWishlist",
  "wpmClientSideSearch",
  "wpmClientPlaceOrder",
  "wpmClientSideOrderReceivedPage"
];

tikTokEvents.forEach(eventName => {
  jQuery(document).on(eventName, handleTikTokEvent.bind(null, eventName));
});

/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load Microsoft Ads premium functions
 * */

(function (wpm, $, undefined) {

	wpm.load_bing_pixel = () => {

		try {
			wpmDataLayer.pixels.bing.loaded = true

			// @formatter:off
			window.uetq = window.uetq || [];

			(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:wpmDataLayer.pixels.bing.uet_tag_id};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");
			// @formatter:on

			set_enhanced_conversion_data()

		} catch (e) {
			console.error(e)
		}
	}

	/**
	 * Set enhanced conversion data
	 *
	 * https://help.ads.microsoft.com/apex/index/3/en/60178
	 */
	const set_enhanced_conversion_data = () => {

		// Only continue if it is an order
		if(!wpmDataLayer?.order) return

		// Only continue if the enhanced conversions are enabled
		if (!wpmDataLayer?.pixels?.bing?.enhanced_conversions) return

		// Only set the data if the user has an email or phone number
		if (!wpmDataLayer?.user?.email?.sha256 && !wpmDataLayer?.user?.phone?.sha256?.e164) return

		let pid = {}

		if (wpmDataLayer?.user?.email?.sha256) {
			pid.em = wpmDataLayer.user.email.sha256
		}

		if (wpmDataLayer?.user?.phone?.sha256?.e164) {
			pid.ph = wpmDataLayer.user.phone.sha256.e164
		}

		window.uetq.push("set", {
			"pid": pid,
		})
	}

	wpm.bing_purchase_ecomm_prodids = () => {

		let prodIds = []

		Object.values(wpmDataLayer.order.items).forEach((orderItem) => {

			if (wpmDataLayer?.shop?.variations_output && 0 !== orderItem.variation_id) {
				prodIds.push(String(wpmDataLayer.products[orderItem.variation_id].dyn_r_ids[wpmDataLayer.pixels.bing.dynamic_remarketing.id_type]))
			} else {
				prodIds.push(String(wpmDataLayer.products[orderItem.id].dyn_r_ids[wpmDataLayer.pixels.bing.dynamic_remarketing.id_type]))
			}
		})

		return prodIds
	}

	wpm.bing_purchase_items = () => {

		let orderItems = []

		Object.values(wpmDataLayer.order.items).forEach(item => {

			let orderItem = {
				quantity: item.quantity,
				price   : item.price,
			}

			if (wpmDataLayer?.shop?.variations_output && 0 !== item.variation_id) {

				orderItem.id = String(wpmDataLayer.products[item.variation_id].dyn_r_ids[wpmDataLayer.pixels.bing.dynamic_remarketing.id_type])
				orderItems.push(orderItem)
			} else {

				orderItem.id = String(wpmDataLayer.products[item.id].dyn_r_ids[wpmDataLayer.pixels.bing.dynamic_remarketing.id_type])
				orderItems.push(orderItem)
			}
		})

		return orderItems
	}

	wpm.getBingDataLayerProducts = () => {

		let productIds = []

		Object.values(wpmDataLayer.products).forEach((product) => {
			productIds.push(String(product.dyn_r_ids[wpmDataLayer.pixels.bing.dynamic_remarketing.id_type]))
		})

		return productIds
	}

	/**
	 * Check if the Microsoft Ads Pixel can be fired
	 *
	 * @return {boolean}
	 */
	wpm.canFireUetq = () => {

		if (!wpmDataLayer?.pixels?.linkedin?.loaded) return false

		// If the user has not given consent for marketing, return false
		if (!wpm.consent.categories.get().marketing) return false

		return true
	}

	/**
	 * Check if the Microsoft Ads Pixel can not be fired
	 *
	 * @return {boolean}
	 */
	wpm.canNotFireUetq = () => {
		return !wpm.canFireUetq()
	}

}(window.wpm = window.wpm || {}, jQuery));

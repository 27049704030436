/**
 * Copyright SweetCode. All rights reserved.
 *
 * Load VWO functions
 * */

(function (wpm, $, undefined) {

	wpm.loadVwoPixel = () => {

		try {

			wpmDataLayer.pixels.vwo.loaded = true

			const vwo_settings = wpmDataLayer.pixels.vwo || {}

			// @formatter:off
			window._vwo_code=window._vwo_code || (function() {

				const account_id          = vwo_settings.account_id
				const settings_tolerance  = vwo_settings.settings_tolerance || 2000
				const library_tolerance   = vwo_settings.library_tolerance || 2500
				const use_existing_jquery = vwo_settings.use_existing_jquery || false
				const version    = vwo_settings.version || 1.5
				const is_spa              = vwo_settings.is_spa || 1
				const hide_element        = vwo_settings.hide_element || "body"
				const hide_element_style  = vwo_settings.hide_element_style || "opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important"

				/* DO NOT EDIT BELOW THIS LINE */
				f=false,d=document,vwoCodeEl=d.querySelector('#vwoCode'),code={use_existing_jquery:function(){return use_existing_jquery},library_tolerance:function(){return library_tolerance},hide_element_style:function(){return'{'+hide_element_style+'}'},finish:function(){if(!f){f=true;var e=d.getElementById('_vis_opt_path_hides');if(e)e.parentNode.removeChild(e)}},finished:function(){return f},load:function(e){var t=d.createElement('script');t.fetchPriority='high';t.src=e;t.type='text/javascript';t.onerror=function(){_vwo_code.finish()};d.getElementsByTagName('head')[0].appendChild(t)},getVersion:function(){return version},getMatchedCookies:function(e){var t=[];if(document.cookie){t=document.cookie.match(e)||[]}return t},getCombinationCookie:function(){var e=code.getMatchedCookies(/(?:^|;)\s?(_vis_opt_exp_\d+_combi=[^;$]*)/gi);e=e.map(function(e){try{var t=decodeURIComponent(e);if(!/_vis_opt_exp_\d+_combi=(?:\d+,?)+\s*$/.test(t)){return''}return t}catch(e){return''}});var i=[];e.forEach(function(e){var t=e.match(/([\d,]+)/g);t&&i.push(t.join('-'))});return i.join('|')},init:function(){if(d.URL.indexOf('__vwo_disable__')>-1)return;window.settings_timer=setTimeout(function(){_vwo_code.finish()},settings_tolerance);var e=d.createElement('style'),t=hide_element?hide_element+'{'+hide_element_style+'}':'',i=d.getElementsByTagName('head')[0];e.setAttribute('id','_vis_opt_path_hides');vwoCodeEl&&e.setAttribute('nonce',vwoCodeEl.nonce);e.setAttribute('type','text/css');if(e.styleSheet)e.styleSheet.cssText=t;else e.appendChild(d.createTextNode(t));i.appendChild(e);var n=this.getCombinationCookie();this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+ +is_spa+'&vn='+version+(n?'&c='+n:''));return settings_timer}};window._vwo_settings_timer = code.init();return code;}());
			// @formatter:on

		} catch (e) {
			console.error(e)
		}
	}

	wpm.vwoLoaded = () => {
		return new Promise((resolve, reject) => {
			if (typeof wpmDataLayer?.pixels?.vwo?.loaded === "undefined") reject()

			let startTime = 0
			let timeout = 5000
			let interval = 200

			const intervalId = setInterval(() => {
				if (window.VWO !== undefined) {
					clearInterval(intervalId)
					return resolve()
				}
				startTime += interval
				if (startTime >= timeout) {
					clearInterval(intervalId)
					pmw.console.error("VWO failed to load. Probably a third party script is blocking it.")
					return reject()
				}
			}, interval)
		})
	}

}(window.wpm = window.wpm || {}, jQuery));
